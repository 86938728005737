main {
  align-content: center;
  background-color: #0e171f;
  box-sizing: border-box;
  display: grid;
  color: white;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  min-height: 100vh;
  padding: 72px 0;
  text-align: center;
}
img {
  justify-self: center;
  width: 50%;
  max-width: 400px;
}
#subtitle {
  padding-top: 30px;
  font-size: 20px;
  color: #f9c941
}

a {
  border-bottom: 1px solid white;
  color: #0076ff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
a:hover {
  border-bottom: 1px solid #0076ff;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '\`';
}

table, th, td {
  border: 2px solid black;
}
th {
  padding-left: 20px;
  padding-right: 20px;
}
#tablaJuegos {
  justify-self: center;
  background-color: gray;
  width: 80%;
  border: solid 1px;
  border-collapse: collapse;
  
}
#tablaJuegos tr {
  height: 50px;
  max-height: 50px;
  background-color: #f9c941;
  color: black;
}
#tablaJuegos img {
  width: 75px;
  height: 75px;
}
#tablaJuegosTitulo {
  text-align: left;
  
}

#tablaJuegosTituloInfo{
  margin: 10px;
}

.FormularioCargaContainer {
  
  justify-self: center;
  width: 400px;
  border: solid 2px white;
  padding: 30px;
}
.FormularioCargaContainer *{
  padding: 2px;
}
.FormularioCarga .form-group{
  display: flex;
  justify-content: space-between;

}
.form-group-especial{
  display: flex;
  justify-content: space-between;

}
.form-group-especial div{
  width: 230px;

}
.form-group-especial input{
}

.FormularioCarga input{
  margin-left: 10px;
}
.FormularioCarga textarea{
  margin-left: 20px;
}
.FormularioCarga button{
  background-color: #f9c941;
  width: 200px;
  height: 50px;
  margin-top: 40px;
}